<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#68D389"
      spinner="bar-fade-scale"
    />
    <v-layout v-if="ServerError" wrap justify-center>
      <v-flex px-5 pt-5 xs12 sm12 md12 lg12 xl12 text-center>
        <ServerError />
      </v-flex>
    </v-layout>
    <v-layout v-if="!ServerError" wrap justify-center>
      <v-flex px-4 xs12 sm6 md4 lg4 xl4 text-left align-self-center>
        <span class="itemHeading">User List </span>
      </v-flex>
      <v-flex xs12 sm6 md3 lg3 xl3 align-self-center>
        <v-text-field
          v-model="keyword"
          append-icon="mdi-magnify"
          solo
          label="Search"
          color="#68d389"
          class="rounded-xl"
          single-line
          hide-details
        ></v-text-field>
      </v-flex>
      <v-flex xs12 sm6 md5 lg5 xl5 align-self-center>
        <download-excel
          :data="json_data"
          :fields="json_fields"
          ref="DownloadExcel"
        >
          <span
            style="
              font-size: 16px;
              font-family: poppinssemibold;
              color: #68d389;
              cursor: pointer;
            "
          >
            Download Excel</span
          >
        </download-excel>
      </v-flex>
      <v-flex xs12 sm6 align-self-center pa-4 text-left>
        <v-layout wrap>
          <v-flex x12 sm6 align-self-center>
            <span style=" font-family: poppinssemibold;">Rows Per Page</span>
          </v-flex>
          <v-flex x12 sm6 lg3 align-self-center>
            <v-select
              hide-details
              dense
              v-model="count"
              :items="[20, 50, 100]"
            ></v-select>
          </v-flex>
        </v-layout>
      </v-flex>

      <v-flex xs12 sm6 align-self-center pa-1 text-left>
        <v-spacer></v-spacer>
      </v-flex>
      <v-flex xs12 align-self-center>
        <v-layout wrap justify-start>
          <template v-for="(item, i) in guest">
            <v-flex pa-5 xs12 xl11 :key="item._id" pr-lg-6 pr-xl-0>
              <v-layout wrap>
                <v-flex xs12 sm6 md4 lg3 xl3>
                  <v-layout wrap>
                    <v-flex text-left xs12>
                      <span class="getText"> Name </span>
                    </v-flex>
                    <v-flex text-left xs12 v-if="item.guestData">
                      <span class="getValue">
                        {{ item.guestData.name }}
                      </span>
                    </v-flex>
                    <v-flex text-left xs12 v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md5 lg3 xl3>
                  <v-layout wrap>
                    <v-flex text-left xs12>
                      <span class="getText"> Email </span>
                    </v-flex>
                    <v-flex text-left xs12 v-if="item.email">
                      <span class="getValue">
                        {{ item.email }}
                      </span>
                    </v-flex>
                    <v-flex text-left xs12 v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>
                <v-flex xs12 sm6 md3 lg3 xl3>
                  <v-layout wrap fill-height>
                    <v-flex xs12 sm12 text-left>
                      <span class="getText"> Phone Number </span>
                    </v-flex>
                    <v-flex
                      xs12
                      sm12
                      text-left
                      align-self-start
                      v-if="item.phone"
                    >
                      <span class="getValue">
                        {{ item.phone }}
                      </span>
                    </v-flex>
                    <v-flex xs12 sm12 text-left align-self-start v-else>
                      <span class="getValue"> Nil </span>
                    </v-flex>
                  </v-layout>
                </v-flex>

                <v-flex xs12 sm6 md3 lg3 xl3>
                  <v-layout wrap>
                    <v-flex xs12 sm4 pa-2>
                      <router-link
                        :to="'UserReport?user=' + item._id + '&count=1&page=1'"
                      >
                        <v-btn
                          color="blue"
                          style="font-family: poppinsmedium"
                          small
                          block
                          outlined
                          >View</v-btn
                        >
                      </router-link>
                    </v-flex>
                    <v-flex xs12 sm4 pa-2 v-if="item.banFrom">
                      <v-dialog
                        persistent
                        v-model="unbandialog"
                        max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="warning"
                            style="font-family: poppinsmedium"
                            small
                            block
                            outlined
                            :disabled="item.banFrom ? false : true"
                            v-on="on"
                            v-bind="attrs"
                          >
                            Permit
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to permit this
                            user?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="unbandialog = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="unbanUser(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <!-- <v-btn
                      color="success"
                      style="font-family: poppinsmedium"
                      small
                      block
                      outlined
                      :disabled="item.banFrom ? false : true"
                      @click="unbanUser(item)"
                      >Permit</v-btn
                    > -->
                    </v-flex>
                    <v-flex xs12 sm4 pa-2 v-else>
                      <v-btn
                        color="success"
                        style="font-family: poppinsmedium"
                        small
                        block
                        outlined
                        :disabled="item.banFrom ? true : false"
                        @click="banUser(item)"
                        >Ban</v-btn
                      >
                    </v-flex>
                    <v-flex xs12 sm4 pa-2>
                      <v-dialog
                        persistent
                        v-model="item.delete"
                        max-width="600px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            small
                            style="font-family: poppinsmedium"
                            class="mr-2"
                            outlined
                            block
                            v-on="on"
                            v-bind="attrs"
                            color="red"
                          >
                            Delete
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title
                            >Are you sure you want to delete this
                            user?</v-card-title
                          >
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="item.delete = false"
                              >Cancel</v-btn
                            >
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="deleteItem(item)"
                              >OK</v-btn
                            >
                            <v-spacer></v-spacer>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-flex>
                    <v-flex xs12 pa-1 v-if="item.banFrom">
                      <span
                        style="
                        color: red;
                        font-family: poppinsregular;
                        font-size: 14px;
                      "
                        >User is banned from {{ formatDate(item.banFrom) }} to
                        {{ formatDate(item.banTo) }}</span
                      >
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs12 xl11 :key="i">
              <v-divider></v-divider>
            </v-flex>
          </template>
        </v-layout>
      </v-flex>
    </v-layout>
    <v-dialog persistent v-model="editdialog" max-width="60%">
      <v-card>
        <v-form v-model="editcatt" ref="editcatt">
          <v-card-title>
            <span class="headline">Select period of Ban for the user</span>
          </v-card-title>
          <!-- <v-card-text> -->

          <!-- </v-card-text> -->
          <!-- <v-card-text> -->
          <!-- </v-card-text> -->
          <!-- <v-card-text> -->
          <v-layout wrap justify-center pa-8>
            <v-flex xs12 sm10 md4 lg4 px-2>
              <v-menu
                v-model="menu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="banItem.fromDate"
                    label="Please Select the Start Date"
                    outlined
                    dense
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="banItem.fromDate"
                  :min="minFrom"
                  @input="
                    (menu = false), checkFrom(banItem.fromDate, banItem.toDate)
                  "
                ></v-date-picker>
              </v-menu>
            </v-flex>

            <v-flex xs12 sm10 md4 lg4 px-2>
              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="banItem.toDate"
                    label="Please Select the End Date"
                    outlined
                    dense
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="banItem.toDate"
                  @input="
                    (menu2 = false), checkTo(banItem.fromDate, banItem.toDate)
                  "
                  :min="minTo"
                ></v-date-picker>
              </v-menu>
            </v-flex>
          </v-layout>
          <!-- </v-card-text> -->
          <v-card-text>Are you sure you want to ban this user?</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="red" text @click="(editdialog = false), getData()">
              Close
            </v-btn>

            <v-btn
              color="blue darken-1"
              text
              :disabled="!editcatt"
              @click="banAccept(), (editdialog = false)"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <div class="pt-2" v-if="pages > 1">
      <v-pagination
        :length="pages"
        :total-visible="7"
        v-model="currentPage"
        color="#68d389"
      ></v-pagination>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import store from "../../../store";

export default {
  data() {
    return {
      appLoading: false,
      search: "",
      currentPage: 1,
      name: "",
      pages: 0,
      count: 20,
      totalData: 0,
      guest: [],
      keyword: "",
      deliverystatus: null,
      totalRows: 0,
      ServerError: false,
      menu: false,
      menu2: false,
      json_fields: {
        Name: "guestData.name",
        PhoneNumber: "phone",
        Email: "email",
      },
      json_data: [],
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      banItem: [],
      unbanItem: [],
      editcatt: false,
      editdialog: false,
      unbandialog: false,
      fromDate: new Date(),
      toDate: new Date(),
      minTo: new Date().toISOString().substr(0, 10),
      minFrom: new Date().toISOString().substr(0, 10),
    };
  },
  watch: {
    count() {
      this.currentPage = 1;
      this.getData();
    },
    keyword() {
      this.currentPage = 1;
      this.getData();
    },
    currentPage() {
      this.getData();
    },
  },
  computed: {
    appType() {
      return store.state.userType;
    },
  },
  mounted() {
    if (this.fromDate) {
      this.fromDate.setDate(this.fromDate.getDate());
      this.fromDate = this.fromDate.toISOString().substr(0, 10);
    }
    if (this.toDate) {
      this.toDate.setDate(this.toDate.getDate());
      this.toDate = this.toDate.toISOString().substr(0, 10);
    }
    this.getData();
  },
  methods: {
    checkFrom(from, to) {
      var date1 = new Date(from);
      var date2 = new Date(to);
      if (date2.getTime() < date1.getTime()) {
        this.banItem.fromDate = this.banItem.toDate;
        // alert("WrongFrom");
      }
      // var diff = date2.getTime() - date1.getTime();
    },
    checkTo(from, to) {
      var date1 = new Date(from);
      var date2 = new Date(to);
      if (date2.getTime() < date1.getTime()) {
        this.banItem.toDate = this.banItem.fromDate;
        // alert("WrongTo");
      }
    },
    banUser(item) {
      this.banItem = item;
      this.editdialog = true;
      // this.banGet(this.banItem._id);
    },
    unbanUser(item) {
      this.unbanItem = item;
      this.unbandialog = true;
      this.banRemove();
    },
    banAccept() {
      this.appLoading = true;
      axios({
        url: "/user/banuser",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.banItem._id,
          from: this.fromDate,
          to: this.toDate,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response) this.getData();
          // this.getExcel();
          // console.log("sd", this.about);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    banRemove() {
      this.appLoading = true;
      axios({
        url: "/user/banuser",
        method: "POST",
        headers: {
          token: localStorage.getItem("token"),
        },
        data: {
          id: this.unbanItem._id,
          from: "",
          to: "",
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response) this.getData();
          // this.getExcel();
          this.unbandialog = false;
          // console.log("sd", this.about);
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    getData() {
      this.appLoading = true;

      if (this.currentPage && this.count) {
        axios({
          url: "/user/getlist",
          method: "GET",
          headers: {
            token: localStorage.getItem("token"),
          },
          params: {
            count: this.count,
            page: this.currentPage,
            keyword: this.keyword,
          },
        })
          .then((response) => {
            this.appLoading = false;
            if (response.data.status) {
              for (var i = 0; i < response.data.data.length; i++) {
                response.data.data[i].dob = this.formatDate(
                  response.data.data[i].dob
                );
              }
              this.guest = response.data.data;
              this.json_data = response.data.data;
              // this.json_data = this.guest;
              // this.getExcel();
              this.name = this.guest.name;
              this.pages = response.data.pages;
              this.totalData = response.data.count;
              this.appLoading = false;
            } else {
              this.msg = response.data.msg;
            }
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      }
    },
    getExcel() {
      this.appLoading = true;
      axios({
        url: "/user/getlist",
        method: "GET",
        headers: {
          token: localStorage.getItem("token"),
        },
        params: {
          // count: this.count,
          // page: this.currentPage,
          keyword: this.keyword,
        },
      })
        .then((response) => {
          this.appLoading = false;
          if (response.data.status) {
            // for (var i = 0; i < response.data.data.length; i++) {
            //   response.data.data[i].dob = this.formatDate(
            //     response.data.data[i].dob
            //   );
            // }
            this.json_data = response.data.data;
            // this.name = this.guest.name;
            // this.pages = response.data.pages;
            // this.totalData = response.data.count;
            this.appLoading = false;
          } else {
            this.msg = response.data.msg;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },

    deleteItem(cat) {
      var data = {};
      data["id"] = cat._id;
      axios({
        url: "/user/removeadmin",
        method: "POST",
        data: data,
        headers: {
          token: localStorage.getItem("token"),
        },
      })
        .then((response) => {
          this.delete = false;
          this.appLoading = false;
          if (response.data.status) {
            this.msg = "Delete Sucessfully";
            this.showsnackbar = true;

            this.getData();
          } else {
            this.msg = response.data.msg;
            this.showsnackbar = true;
          }
        })
        .catch((err) => {
          this.appLoading = false;
          this.ServerError = true;
          console.log(err);
        });
    },
    // deleteItem(r) {
    //   var data = {};
    //   data["id"] = r._id;
    //   axios({
    //     url: "/guest/remove",
    //     method: "POST",
    //     data: data,
    //     headers: {
    //       "x-auth-token": localStorage.getItem("token"),
    //     },
    //   })
    //     .then((response) => {
    //       this.delete = false;
    //       this.appLoading = false;
    //       if (response.data.status) {
    //         this.msg = "Delete Sucessfully";
    //         this.showsnackbar = true;
    //         this.getData();
    //       } else {
    //         this.msg = response.data.msg;
    //         this.showsnackbar = true;
    //       }
    //     })
    //     .catch((err) => {
    //       this.appLoading = false;
    //       this.ServerError = true;
    //       console.log(err);
    //     });
    // },
    formatDate(item) {
      var dt = new Date(item);
      var day = dt.getDate();
      var year = dt.getFullYear();
      dt = dt.toString();
      var strTime = day + " " + dt.slice(4, 7) + " " + year;
      return strTime;
    },
  },
};
</script>
